import $api from '../http';
import { AxiosResponse } from 'axios';
import { IEstimate } from '../models/IEstimate';



export default class EstimatesService {
  static async createEstimate(data: Omit<IEstimate, '_id'| 'traner' | 'from'> & { traner: string; from: string} ): Promise<AxiosResponse<{ estimate: IEstimate }>> {
    return $api.post('/service1/estimates/', data, {
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
  static async getEstimates(searchQuery: string): Promise<AxiosResponse<{ estimates: IEstimate[] }>> {
    return $api.get('/service1/estimates/', {
      params: { searchQuery }
    });
  }
  static async setAdmComment(estimateId: string, comment: string): Promise<AxiosResponse<{ estimate: IEstimate }>> {
    return $api.patch(`/service1/estimates/admcomment/${estimateId}`, {
      comment
    }, {
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
}