import x from '../../assets/х.png';

const TournamentModal = ({ setOpenModal }: any) => {
  return (
    <>
      <div className="left-0 absolute opacity-55 m-0 bg-fil backdrop-blur-3xl h-[100vh] bg-gray-400 w-[100vw]"></div>
      <div className="bg-white w-[1200px] absolute h-[70vh] rounded-3xl border-2 border-[#B7975AD9]">
        <img
          src={x}
          onClick={() => setOpenModal(false)}
          className="float-right m-5 h-[25px]"
        />
        <p className="w-full h-full text-lg mt-5 font-bold text-center italic p-5">
          Мы отличный инструмент! Вы можете использовать их для многих вещей,
          таких как: <br />
          <p className='mt-5'>
          Команды для вашего клуба OTB
          <br />
          Онлайн -клуб или лига
          <br />
          Фан -клубы игроков и стримеров <br />
          Общие интересы <br />
          И еще много причин, почти без ограничения. <br /></p>
          <p className='mt-5'>
          Тем не менее, есть несколько вещей, которые не допускаются на личессе,
          поскольку они злоупотребляют инфраструктурой:</p>
          <br />
          <p className='mt-5'>
          Предоставление людям за присоединение к вашей команде (кроме
          стандартных командных преимуществ, таких как участие в турнире и вход
          Simul)
          <br />
          Продажа последователей команды или членов команды, или обмен
          подписчиками или членами команды
          <br />
          Присоединение к командам из нескольких учетных записей или поощрение
          такого поведения
          <br />
          Предложение руководства команды в рамках сделки для подписчиков/членов
          команды
          <br />
          Команды Lichess предназначены для поощрения сообществ и лучше
          позволить нам весело играть в шахматы, а не как предметы
          коллекционеров. Обратите внимание, что неспособность следовать этим
          рекомендациям может привести к закрытию команды и учетной записи.</p>
        </p>
      </div>
    </>
  );
};

export default TournamentModal;
