import amFlag from './assets/flags/am.png';
import enFlag from './assets/flags/en.png';
import ruFlag from './assets/flags/ru.png';
import {
  ECountries,
  ELessonFormatTypes,
  EUserLanguages,
} from './models/User.ts';
import { Elanguages } from './store/reducers/TranslateSlice.ts';
import { ITranslateItemString, translations } from './utils/translations.tsx';

const {
  armenianText,
  russianText,
  englishText,
  frenchText,
  germanText,
  hindiText,
  spanishText,
  itaianText,
  otherLanguagesText,
  russiaText,
  europeText,
  americaText,
  canadaText,
  armeniaText,
  groupText,
  individualText,
  groupPlusIndividualText,
} = translations.profile;

export type SelectOptions<T> = {
    [key in T as string]: {
        text: ITranslateItemString,
        slug: T
    }
}
export type UserLanguagesSelectOptions = SelectOptions<EUserLanguages>;

export type UserCountiesSelectOptions = SelectOptions<ECountries>;

export type UserFormatOptions = SelectOptions<ELessonFormatTypes>;


export const languages: {
  [key in Elanguages]: {
    text: Elanguages;
    img: string;
    name: string;
    shortName: string;
  };
} = {
  ru: {
    text: Elanguages.RU,
    img: ruFlag,
    name: 'Русский',
    shortName: 'RUS',
  },
  am: {
    text: Elanguages.AM,
    img: amFlag,
    name: 'Հայերեն',
    shortName: 'ARM',
  },
  en: {
    text: Elanguages.EN,
    img: enFlag,
    name: 'English',
    shortName: 'ENG',
  },
};

export const userLanguages: UserLanguagesSelectOptions = {
  [EUserLanguages.RUS]: {
    text: russianText,
    slug: EUserLanguages.RUS,
  },
  [EUserLanguages.ARM]: {
    text: armenianText,
    slug: EUserLanguages.ARM,
  },
  [EUserLanguages.ENG]: {
    text: englishText,
    slug: EUserLanguages.ENG,
  },
  [EUserLanguages.FRA]: {
    text: frenchText,
    slug: EUserLanguages.FRA,
  },
  [EUserLanguages.GER]: {
    text: germanText,
    slug: EUserLanguages.GER,
  },
  [EUserLanguages.HINDI]: {
    text: hindiText,
    slug: EUserLanguages.HINDI,
  },
  [EUserLanguages.SPA]: {
    text: spanishText,
    slug: EUserLanguages.SPA,
  },
  [EUserLanguages.ITA]: {
    text: itaianText,
    slug: EUserLanguages.ITA,
  },
  [EUserLanguages.OTHER]: {
    text: otherLanguagesText,
    slug: EUserLanguages.OTHER,
  },
};

export const userCountries: UserCountiesSelectOptions = {
  [ECountries.ARMENIA]: {
    slug: ECountries.ARMENIA,
    text: armeniaText,
  },
  [ECountries.RUSSIA]: {
    slug: ECountries.RUSSIA,
    text: russiaText,
  },
  [ECountries.EUROPA]: {
    slug: ECountries.EUROPA,
    text: europeText,
  },
  [ECountries.AMERICA]: {
    slug: ECountries.AMERICA,
    text: americaText,
  },
  [ECountries.CANADA]: {
    slug: ECountries.CANADA,
    text: canadaText,
  },
};
export const lessonFormatTypes: UserFormatOptions = {
  [ELessonFormatTypes.GROUP]: {
    slug: ELessonFormatTypes.GROUP,
    text: groupText,
  },
  [ELessonFormatTypes.IND]: {
    slug: ELessonFormatTypes.IND,
    text: individualText,
  },
  [ELessonFormatTypes.GROUPIND]: {
    slug: ELessonFormatTypes.GROUPIND,
    text: groupPlusIndividualText,
  },
};

export const themesForLessonRestriction = 3;
