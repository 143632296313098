import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getInfoText, editInfoText } from '../../store/reducers/InfoTextsSlice';
import Button from '../../components/UI/Button';
import { EInfoTextFields, IInfoText } from '../../models/IInfoTexts';
import { Elanguages } from '../../store/reducers/TranslateSlice';
import ChangeLanguageBtns from '../../components/UI/ChangeLanguageBtns';
import Modal from '../../components/UI/Modal';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import 'froala-editor/js/plugins.pkgd.min.js';

const RecomendationText: FC = () => {
  const dispatch = useAppDispatch();
  const { recomendationText } = useAppSelector((state) => state.InfoTextsSlice);
  const [language, setLanguage] = useState<Elanguages>(Elanguages.RU);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [text, setText] = useState<IInfoText>({
    [Elanguages.RU]: '',
    [Elanguages.EN]: '',
    [Elanguages.AM]: '',
  });

  useEffect(() => {
    void dispatch(getInfoText({ field: EInfoTextFields.recomendationText }));
  }, [dispatch]);

  useEffect(() => {
    if (recomendationText) {
        console.log(recomendationText);
      setText(recomendationText);
    }
  }, [recomendationText]);

  const handleSave = () => {
    void dispatch(editInfoText({ field: EInfoTextFields.recomendationText, text }))
    setIsModalOpen(true);
  };

  return (
    <>
      <ChangeLanguageBtns className="mt-2" language={language} onLangChange={setLanguage}/>
      <FroalaEditorComponent
        model={text[language]}
        onModelChange={(value: string) => setText({ ...text, [language]: value })}
        tag='textarea'
      />
      <Button onClick={handleSave} className="mt-5 bg-green-500 hover:bg-green-600">Сохранить</Button>
      <Modal active={isModalOpen} setActive={setIsModalOpen}>
        <div className="p-4 text-center">
            <h2 className="text-xl font-bold mb-2">Успешно!</h2>
            <p>Данные сохранены</p>
            <Button onClick={() => setIsModalOpen(false)} className="mt-4">Закрыть</Button>
        </div>
      </Modal>
    </>
  );
};

export default RecomendationText;