import { FC, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { getEstimates, setAdmComment as setAdmCommentThunk } from "../store/reducers/EstimatesSlice";
import { IEstimate } from "../models/IEstimate";
import { UserRoles } from "../utils/userRoles";
import { translations } from "../utils/translations";
import UsersSearch from "../components/Widgets/UsersSearch";
import { User } from "../models/User";
import Input from "../components/UI/Input";
import { useDebounce } from "../hooks/useDebounce";

enum Categories {
  MyEst = 'MyEst',
  CommonEst = 'CommonEst',
  TranerEst = 'TranerEst',
  AdministrationEst = 'AdministrationEst' 
}

const categoriesNames = {
  [Categories.MyEst]: {
    ru: "Мои Оценки",
    am: "Իմ գնահատականները",
    en: "My Grades"
  },
  [Categories.CommonEst]: {
    ru: "Общие для всех участников чата",
    am: "Ընդհանուր բոլոր զրույցի մասնակիցների համար",
    en: "Common for all chat participants"
  },
  [Categories.TranerEst]: {
    ru: "Оценки тренера",
    am: "Մարզչի գնահատականները",
    en: "Coach's ratings"
  },
  [Categories.AdministrationEst]: {
    ru: "Оценки администрации",
    am: "Ադմինիստրացիայի գնահատականները",
    en: "Administration's ratings"
  },
}

const MyEstimatesPage:FC = () => {
  const dispatch = useAppDispatch();
  const language = useAppSelector(state => state.TranslateSlice.language);
  const estimates = useAppSelector(state => state.EstimatesSlice.estimates);
  const user = useAppSelector(state => state.UserSlice.user);
  const [categorie, setCategorie] = useState<Categories>(Categories.MyEst);
  const [currentEstimates, setCurrentEstimates] = useState<IEstimate[]>([]);
  const [admComment, setAdmComment] = useState<string>('');
  const [dropdown, setDropDown] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [tranerSearch, setTranerSearch] = useState<User>(); 
  const { 
    avgGradeText,
    ratingText,
    toWhomText,
    fromWhomText,
    noRating,
    sendText,
    administrationComment,
    commentText,
    leaveAdministrationComment
  } = translations.estimate;

  const getEstimatesDebounced = useDebounce((query: string) => {
    void dispatch(getEstimates(query));
  }, 500);

  useEffect(() => {
    if (user._id) {
      void getEstimatesDebounced('');
    }
	}, [user._id, dispatch, getEstimatesDebounced]);

  useEffect(() => {
    getEstimatesDebounced(searchQuery)
  }, [searchQuery, getEstimatesDebounced]);

  useEffect(() => {
    const estFiltered = estimates.filter(est => !tranerSearch || tranerSearch._id === est.traner._id);
    switch(categorie) {
      case Categories.MyEst:
        setCurrentEstimates(estFiltered.filter(est => est.from._id === user._id || est.traner._id === user._id));
        break;
      case Categories.CommonEst:
        setCurrentEstimates(estFiltered.filter(est => est.parentsCanSee));
        break;
      case Categories.AdministrationEst:
        setCurrentEstimates(estFiltered.filter(est => !est.parentsCanSee && !est.tranerCanSee));
        break;
      case Categories.TranerEst:
        setCurrentEstimates(estFiltered.filter(est => est.tranerCanSee));
    }
  }, [categorie, setCurrentEstimates, estimates, user._id, tranerSearch])

  const avgEst = useMemo(() => ((currentEstimates.reduce((prev, curr) => prev + curr.estimate, 0) / currentEstimates.length) || 0).toFixed(2), [currentEstimates]);

  const setAdmCommentHandler = (estimateId: string) => {
    void dispatch(setAdmCommentThunk({ estimateId, comment: admComment }));
    setAdmComment('');
  }

  return (
    <div className="w-full p-5 flex flex-col gap-5">
				<div className="flex gap-2 justify-center flex-wrap">
          {Object.values(Categories).map(cat => (
            <button
              key={cat}
              onClick={() => setCategorie(cat)}
              className={`px-4 py-2 rounded ${categorie === cat ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            >
              {categoriesNames[cat][language]}
            </button>
          ))}
        </div>
        <div className="flex justify-between">
          {
            [Categories.CommonEst, Categories.AdministrationEst, Categories.TranerEst].includes(categorie) &&
            [UserRoles.DIRECTOR, UserRoles.ZDIRECTOR].includes(user.role) &&
            <UsersSearch dropdown={dropdown} setDropDown={setDropDown} role={UserRoles.TRANER} placeholder="Найти тренера" onChangeUser={setTranerSearch}/>
          }
          <Input
            wrapperClasses="ml-auto"
            type="search"
            placeholder="Найти комментарий"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <p className="text-lg">{avgGradeText[language]} <strong>{avgEst}</strong></p>
        {
          currentEstimates.map(est => (
            <div key={est._id} className="bg-slate-300 p-5 rounded-lg">
              <p><strong>{ratingText[language]}</strong> {est.estimate}-{est.icon}</p>
              <p><strong>{toWhomText[language]}</strong> {est.traner.name} {est.traner.sname} ({est.traner.email})</p>
              <p><strong>{fromWhomText[language]}</strong> {est.from.name} {est.from.sname} ({est.from.email})</p>
              {est.comment && <p><strong>{commentText[language]}</strong> {est.comment}</p>}
              {est.administrationComment && <p><strong>{administrationComment[language]}</strong> {est.administrationComment}</p>}
              {!est.administrationComment && [UserRoles.DIRECTOR, UserRoles.ZDIRECTOR].includes(user.role) && <div className="flex flex-col mt-2 max-w-md">
                <textarea
                  value={admComment}
                  onChange={e => setAdmComment(e.target.value)}
                  className="rounded-md p-3"
                  placeholder={leaveAdministrationComment[language]}
                ></textarea>
                <button
                  onClick={() => setAdmCommentHandler(est._id)}
                  className={`mt-1 px-4 py-2 rounded bg-blue-500 text-white`}
                  disabled={!admComment}
                >
                  {sendText[language]}
                </button>
              </div>}
            </div>
          ))
        }
        { !currentEstimates.length && <p className="text-center mt-2 text-lg">{noRating[language]}</p>}
    </div>
  );
}

export default MyEstimatesPage;